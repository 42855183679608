import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';

import { ControlLabel, Form, FormGroup, Modal } from 'react-bootstrap';
import Dropzone from 'react-dropzone';

import { ACCEPTED_TYPES } from '@core/models/Attachment';
import { getDataURL } from '@core/utils';

import { Alert, Button, ButtonToolbar, Card, Dropdown, MenuItem } from '@components/dmp';

import API from '@root/ApiClient';

@autoBindMethods
class DocCompare extends Component {
  static defaultProps = {};

  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      compareReady: false,
      dataURL: null,
      previewURL: null,
      isComparing: false,
      originalFile: null,
      modifiedFile: null,
      showPreview: false,
      failed: false,
      message: '---',
      returnType: 'pdf',
      fileName: 'result.pdf',
    };

    this.downloadRef = React.createRef();
  }

  fileToArrayBuffer(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = () => {
        reject(reader.error);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  fileUploaded(file, name) {
    this.fileToArrayBuffer(file).then((buffer) => {
      this.setState({ [name]: { file, buffer } });
      this.checkCompareReady();
    });
  }

  checkCompareReady() {
    const { originalFile, modifiedFile } = this.state;
    this.setState({ compareReady: originalFile && modifiedFile });
  }

  originalUploaded(file) {
    this.fileUploaded(file[0], 'originalFile');
  }

  modifiedUploaded(file) {
    this.fileUploaded(file[0], 'modifiedFile');
  }

  selectReturnType(returnType) {
    this.setState({ returnType });
    this.resetResults();
  }

  resetInput() {
    this.setState({ originalFile: null, modifiedFile: null, failed: false });
  }

  resetResults() {
    this.setState({ dataURL: null, previewURL: null, failed: false });
  }

  reset() {
    this.resetInput();
    this.resetResults();
  }

  removeOriginal(event) {
    event.stopPropagation();
    this.setState({ originalFile: null });
  }

  removeModified(event) {
    event.stopPropagation();
    this.setState({ modifiedFile: null });
  }

  showPreview() {
    this.setState({ showPreview: true });
  }

  hidePreview() {
    this.setState({ showPreview: false });
  }

  createDownloadFileName() {
    const { modifiedFile, returnType } = this.state;

    const parts = modifiedFile.file.name.split('.');
    parts.pop(); // Discard the incoming extension
    parts.push(parts.pop() + '-compared');
    parts.push(returnType);

    return parts.join('.');
  }

  arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  async compareFiles() {
    const { originalFile, modifiedFile, returnType } = this.state;
    this.setState({ isComparing: true });
    const payload = {
      options: {
        CompareFormatting: 'false',
      },
      returnType,
      doc1: this.arrayBufferToBase64(originalFile.buffer),
      doc2: this.arrayBufferToBase64(modifiedFile.buffer),
    };
    const result = await API.call('compareFiles', payload);
    if (typeof result !== 'string' && !result.success) {
      this.setState({ failed: true, message: result.message, isComparing: false });
      return;
    }
    const buffer = Buffer.from(result, 'base64');
    const dataURL = getDataURL(buffer.buffer);
    const previewURL =
      returnType == 'pdf' ? URL.createObjectURL(new Blob([buffer], { type: 'application/pdf' })) : null;
    const fileName = this.createDownloadFileName();
    this.setState({ dataURL, previewURL, fileName, isComparing: false });
  }

  downloadFile() {
    this.downloadRef.current.click();
  }

  render() {
    const {
      compareReady,
      showPreview,
      isComparing,
      failed,
      originalFile,
      modifiedFile,
      returnType,
      dataURL,
      previewURL,
      fileName,
    } = this.state;

    return (
      <main className="dashboard">
        <div id="page-doc-compare">
          <div className="wrapper">
            <div className="title-bar">
              <h1 className="d-flex">Document Compare</h1>
            </div>
            <Card>
              <Form>
                <div className="contents">
                  {failed && <Alert dmpStyle="danger">Something went wrong!</Alert>}
                  <ControlLabel>Files to Compare</ControlLabel>
                  <FormGroup>
                    <Dropzone
                      className="file-up"
                      activeClassName="uploader active"
                      accepted={ACCEPTED_TYPES.WORD.mime}
                      multiple={false}
                      onDropAccepted={this.originalUploaded}
                      data-cy="file-up"
                    >
                      {originalFile && (
                        <div className="hit-area">
                          <div className="instructions">
                            <h4>{originalFile?.file.name}</h4>
                          </div>
                          <Button className="clear" onClick={this.removeOriginal}>
                            ×
                          </Button>
                        </div>
                      )}

                      {!originalFile && (
                        <div className="hit-area">
                          <div className="instructions">
                            <h4>Original</h4>
                            <small>Accepted: DOCX.</small>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="spacer"></div>
                    <Dropzone
                      className="file-up"
                      activeClassName="uploader active"
                      accepted={ACCEPTED_TYPES.WORD.mime}
                      multiple={false}
                      onDropAccepted={this.modifiedUploaded}
                      data-cy="file-up"
                    >
                      {modifiedFile && (
                        <div className="hit-area">
                          <div className="instructions">
                            <h4>{modifiedFile?.file.name}</h4>
                          </div>
                          <Button className="clear" onClick={this.removeModified}>
                            ×
                          </Button>
                        </div>
                      )}

                      {!modifiedFile && (
                        <div className="hit-area">
                          <div className="instructions">
                            <h4>Modified</h4>
                            <small>Accepted: DOCX.</small>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </FormGroup>
                  <ControlLabel>Result File Type</ControlLabel>
                  <Dropdown id="dd-compare-type-select" title={returnType} onSelect={this.selectReturnType} block>
                    <MenuItem key="docx" eventKey="docx">
                      docx
                    </MenuItem>
                    <MenuItem key="pdf" eventKey="pdf">
                      pdf
                    </MenuItem>
                  </Dropdown>
                  <ButtonToolbar>
                    {dataURL === null && (
                      <Button
                        dmpStyle={isComparing ? 'default' : 'primary'}
                        loading={isComparing}
                        disabled={!compareReady || isComparing}
                        onClick={this.compareFiles}
                      >
                        Compare Files
                      </Button>
                    )}
                    {dataURL !== null && (
                      <div className="file-download">
                        <a ref={this.downloadRef} href={dataURL} download={fileName}></a>
                      </div>
                    )}
                    {dataURL !== null && (
                      <Button dmpStyle="primary" icon={'fv_' + returnType} onClick={this.downloadFile}>
                        Download
                      </Button>
                    )}
                    {dataURL !== null && (
                      <Button
                        dmpStyle="primary"
                        icon="preview"
                        disabled={returnType == 'docx'}
                        onClick={this.showPreview}
                      >
                        Preview PDF
                      </Button>
                    )}
                    {(dataURL !== null || failed) && (
                      <Button dmpStyle="link" icon="undo" onClick={this.reset}>
                        Reset
                      </Button>
                    )}
                  </ButtonToolbar>
                </div>
              </Form>
            </Card>
            <Modal dialogClassName="pdf-preview" show={showPreview} onHide={this.hidePreview} data-cy="pdf-preview">
              <Modal.Body className="outer-paper-layout">
                <object data={`${previewURL}#toolbar=0&navpanes=0`} type="application/pdf" />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </main>
    );
  }
}

export default DocCompare;
