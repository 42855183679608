import _ from 'lodash';

import Version from '@core/models/Version';
import API from '@root/ApiClient';
import Fire from '@root/Fire';

class AIBlockRunner {
  autorun(sourceSections) {
    // CJA: Disabling autorun for now due to bugginess (copy a template with ai blocks and autorun will run amok).
    /*
    // TODO next: figure out how to encpasulate the API.summarize call into a reusable class
    // that's NOT tied to a component (ie remove from ListSection.jsx)
    // so that we can reuse it both here and in DealView
    // ...maybe follow the VariableIndex paxttern
    const queue = _.filter(sourceSections, (sec) => sec.aiPrompt?.canAutorun);

    if (queue.length > 0) {
      const sec = queue[0];
      console.log(`[${sec.name}] - AUTORUN`);
      this.runBlock(sec);
    }
    */
  }

  // TODO: CJA: This is to work around the problems described here:
  // https://trello.com/c/X9h8bCxM/3422-ai-generation-needs-to-be-managed-on-the-backend
  async stopRunningHack(section) {
    const { aiPrompt } = section;
    aiPrompt.isRunning = false;
    await Fire.saveSection(section, { aiPrompt: aiPrompt.json });
  }

  async runBlock(section, onChunk = _.noop, user = null) {
    const { aiPrompt } = section;

    let source = aiPrompt.dataSourceAI;

    const useJSON = aiPrompt.responseType === 'list';

    // Store isRunning state in db instead of local component state
    aiPrompt.isRunning = true;
    await Fire.saveSection(section, { aiPrompt: aiPrompt.json });

    // This should be the only place in the whole app where the actual AI call gets made
    let skynet,
      assistResult,
      assistResultWithCitations = '';

    if (aiPrompt.responseType === 'list') {
      // First auto-clear any children in the target list (AIBlock)
      await Fire.clearList(section);
    }

    // Vinnie blocks use API.assist instead of API.summarize
    if (section.isVinnie) {
      // Whether streaming or not, it's going to replace current content, so clear it out first
      // in case there was a previous response there
      if (aiPrompt.streaming) {
        //when streaming clear the content and do not version it.
        await Fire.saveSection(section, { content: null });
      }

      const assistArgs = { dealID: section.deal.dealID, sectionID: section.id };

      // When streaming is on, this will enable us to continuously render chunks of the result via onChunk callback,
      // which will get called piece by piece prior to the API call resolving
      if (aiPrompt.streaming) {
        assistResultWithCitations = await API.stream('assist', assistArgs, onChunk);
      } else {
        assistResultWithCitations = await API.call('assist', assistArgs);
      }
      //We now recieve citations with files_search implemented. Remove all citations because theres no reason to cite a file we cannot access.
      assistResult = assistResultWithCitations.replace(/\【.*?】/g, '');

      // We get here once response is done (whether streamed or not)
      // API.assist() returns a simple string, but we'll mimic the structure of API.summarize()
      // so that we can use the same routine below to handle (save it etc)
      skynet = { result: [assistResult], success: true };
    } else {
      skynet = await API.call('summarize', {
        aiPrompt: aiPrompt.json,
        source,
        dealID: section.deal.dealID,
        json: useJSON,
      });
    }

    console.log('runBlock(): skynet=', skynet);

    if (!skynet?.success || !skynet?.result?.[0]) {
      aiPrompt.lastResponse = null;
      aiPrompt.isRunning = false;
      aiPrompt.lastError = skynet?.error ?? 'Unknown error';
      await Fire.saveSection(section, { aiPrompt: aiPrompt.json });
      return;
    }

    switch (aiPrompt.responseType) {
      // If we're expecting multiple items back, handle it as an array and generate multiple sections
      case 'list':
        // await this.handleJSONItems(section, skynet.result);

        // For now we're not doing any processing on the json array response,
        // just rendering it as child items for better formatting and subsequent edits/tracking
        const sections = _.map(skynet.result, (item) => {
          switch (typeof item) {
            case 'string':
              return { content: item };
            case 'object':
              return {
                displayname: item.title || null,
                content: item.body || null,
              };
            default:
              // TODO: some sort of template/Repeater type thing
              return { content: JSON.stringify(item) };
          }
        });

        console.log('runBlock(): sections=', sections);

        if (sections.length > 0) {
          await Fire.addItemBatch(section, sections, null, true);
        }

        break;
      // Or we can store the result inside a variable (once we confirm it's a valid variable)
      case 'variable':
        const targetVariable = section.deal.variables[aiPrompt.outputVariable];
        if (!aiPrompt.outputVariable) {
          console.error(`No target variable specified for AI Block [${section.name}]`);
        } else if (!targetVariable) {
          console.error(
            `Target variable [${aiPrompt.outputVariable}] specified for AI Block [${section.name}] does not exist`
          );
        }
        // Now we've confirmed we've got a valid target -- save it!
        else {
          const result = skynet.result[0];
          await Fire.saveVariable(section.deal, targetVariable, result);
        }
        break;
      // Default/legacy -- put it in the body of this AI Block Section
      case 'block':
      default:
        //if its the first go and we do not have any content save the section.
        //if not it means we are regenerating the block so we want to version it.
        if (!section.hasBodyText || aiPrompt.streaming) {
          await Fire.saveSection(section, { content: skynet.result[0] });
        } else {
          const newVersion = new Version({
            user: user.id,
            title: section.displayname || null,
            body: skynet.result[0] || null,
          });

          await Fire.saveSectionVersion(section, newVersion.json.user, newVersion.json.title, newVersion.json.body);
        }
        break;
    }

    // Always store the latest raw response to avoid constantly triggering autorun
    aiPrompt.lastResponse = skynet.result[0];
    aiPrompt.isRunning = false;
    aiPrompt.lastError = null;
    await Fire.saveSection(section, { aiPrompt: aiPrompt.json });
  }
}

const aiBlockRunner = new AIBlockRunner();

// Export singleton instance so we can use it throughout app
export default aiBlockRunner;
